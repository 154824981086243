import { useState, useEffect } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { MenuItem, Select } from "@mui/material";

function AdminDashboard({ setRefresh }: any) {
  interface Dashboard {
    Number_of_all_questions: string;
    Number_of_tts_questions: string;
    Number_of_normal_questions: string;
    Number_of_stt_questions: string;
    total_input_tokens: string;
    total_output_tokens: string;
    total_stt_minutes: string;
    total_tts_characters: string;
    number_of_users: string;
  }

  interface SchoolData {
    id: string;
    name: string;
    max_students: string;
    current_users: string;
  }

  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [dashboardData, setDashboardData] = useState<Dashboard>();
  // const [dashboardData, setDashboardData] = useState<Dashboard>({
  //   Number_of_all_questions: (Math.floor(Math.random() * 1000) + 1).toString(),
  //   Number_of_tts_questions: (Math.floor(Math.random() * 500) + 1).toString(),
  //   Number_of_normal_questions: (
  //     Math.floor(Math.random() * 500) + 1
  //   ).toString(),
  //   Number_of_stt_questions: (Math.floor(Math.random() * 100) + 1).toString(),
  //   total_input_tokens: (Math.floor(Math.random() * 10000) + 1000).toString(),
  //   total_output_tokens: (Math.floor(Math.random() * 10000) + 1000).toString(),
  //   total_stt_minutes: (Math.random() * 60).toFixed(2), // Random minutes with 2 decimals
  //   total_tts_characters: (
  //     Math.floor(Math.random() * 100000) + 1000
  //   ).toString(),
  //   number_of_users: (Math.floor(Math.random() * 1000) + 10).toString(),
  // });
  const [schoolsData, setSchoolsData] = useState<SchoolData[] | null>(null);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [selectedModel, setSelectedModel] = useState("all");
  const [selectedTtsModel, setSelectedTtsModel] = useState("all");
  const [selectedSttModel, setSelectedSttModel] = useState("all");
  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  // Formatting date to 'YYYY-MM-DD' for input value
  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const [fromDate, setFromDate] = useState<string>(formatDate(oneYearAgo));
  const [toDate, setToDate] = useState<string>(formatDate(today));

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
      setSchoolsData
    );
  }, []);

  useEffect(() => {
    setLoading(true);

    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append("school_id", selectedSchool);
        formData.append("model", selectedModel);
        formData.append("tts_model", selectedTtsModel);
        formData.append("stt_model", selectedSttModel);

        if (fromDate !== undefined) {
          formData.append("from", fromDate);
        }
        if (toDate !== undefined) {
          formData.append("to", toDate);
        }

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_ADMIN_DASHBOARD_DATA}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();

    setLoading(false);
  }, [selectedSchool, fromDate, toDate, selectedModel]);

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFromDate = e.target.value;
    if (toDate && new Date(newFromDate) > new Date(toDate)) {
      toast.error("From date cannot be after to date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setFromDate(newFromDate);
    }
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToDate = e.target.value;
    if (fromDate && new Date(newToDate) < new Date(fromDate)) {
      toast.error("To date cannot be before from date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setToDate(newToDate);
    }
  };

  // if (loading) {
  //   return (
  //     <div className="flex justify-center mt-10">
  //       <ImSpinner size={30} className=" animate-spin" />
  //     </div>
  //   );
  // }

  return (
    <section
      className="overflow-scroll h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div>
        <div className="text-[22px] font-bold">Filter:</div>
        <div className="grid grid-cols-3 items-center content-center flex-wrap w-full gap-2">
          <div className="flex flex-row items-center gap-2">
            <label className="pb-2 text-[20px]">From:</label>
            <input
              required
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            />
          </div>
          <div className="flex flex-row items-center gap-2">
            <label className="pb-2 text-[20px]">To:</label>
            <input
              required
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            />
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">School:</label>
            <Select
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              {schoolsData &&
                schoolsData.map((school, index) => (
                  <MenuItem id="tone-div" key={index} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">Model:</label>
            <Select
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem key="no" value="gpt-4">
                gpt-4
              </MenuItem>
              <MenuItem key="yes" value="gpt-4o">
                gpt-4o
              </MenuItem>
              <MenuItem key="yes" value="gpt-4o-mini">
                gpt-4o-mini
              </MenuItem>
            </Select>
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">STT Model:</label>
            <Select
              value={selectedSttModel}
              onChange={(e) => setSelectedSttModel(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem key="no" value="whisper">
                Whisper
              </MenuItem>
            </Select>
          </div>
          <div className=" flex flex-row  items-center gap-2">
            <label className="pb-2 text-[20px]">TTS Model:</label>
            <Select
              value={selectedTtsModel}
              onChange={(e) => setSelectedTtsModel(e.target.value)}
              className=" w-52 "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              <MenuItem key="no" value="TTS">
                TTS
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center mt-10">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      ) : (
        dashboardData && (
          <div className="p-2 border-t-2 mt-5 pt-5">
            <div className="grid grid-cols-3 gap-6">
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of All Questions
                </h2>
                <p className="text-blue-600 font-bold text-[20px]">
                  {dashboardData.Number_of_all_questions}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of TTS Questions
                </h2>
                <p className="text-blue-600 font-bold text-[20px]">
                  {dashboardData.Number_of_tts_questions}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-blue-600">
                <h2 className="text-lg font-semibold">
                  Number of STT Questions
                </h2>
                <p className="text-blue-600 font-bold text-[20px]">
                  {dashboardData.Number_of_stt_questions}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-green-500">
                <h2 className="text-lg font-semibold">
                  Number of Normal Questions
                </h2>
                <p className="text-green-600 font-bold text-[20px]">
                  {dashboardData.Number_of_normal_questions}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-green-500">
                <h2 className="text-lg font-semibold">Total Input Tokens</h2>
                <p className="text-green-600 font-bold text-[20px]">
                  {dashboardData.total_input_tokens}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-green-500">
                <h2 className="text-lg font-semibold">Total Output Tokens</h2>
                <p className="text-green-600 font-bold text-[20px]">
                  {dashboardData.total_output_tokens}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-orange-500">
                <h2 className="text-lg font-semibold">Total STT Minutes</h2>
                <p className="text-orange-600 font-bold text-[20px]">
                  {dashboardData.total_stt_minutes}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-orange-500">
                <h2 className="text-lg font-semibold">Total TTS Characters</h2>
                <p className="text-orange-600 font-bold text-[20px]">
                  {dashboardData.total_tts_characters}
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg shadow border-l-8 border-l-orange-500">
                <h2 className="text-lg font-semibold">Number of Users</h2>
                <p className="text-orange-600 font-bold text-[20px]">
                  {dashboardData.number_of_users}
                </p>
              </div>
            </div>
          </div>
        )
      )}
      {/* <div className="lg:grid lg:grid-cols-10 ">
        <div className=" flex flex-col gap-3 justify-between col-span-3 p-4">
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#9665FF]">
              <div>
                You are enrolled in {dashboardData?.courses_number} courses!
              </div>
              <div className="w-full flex justify-center">
                <div
                  onClick={() => (window.location.href = "/courses")}
                  className="bg-white text-[#4D30B5] p-2 rounded-md text-[15px]  text-center w-fit mt-5 cursor-pointer mb-2 hover:bg-gray-200 duration-300"
                >
                  View Courses
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#9665FF]">
              You are a {dashboardData?.student_level_name} student.
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#4AD4F0]">
              <div>Input Tokens Used:</div>
              <div>
                {dashboardData?.input_tokens_today}/
                {dashboardData?.max_input_tokens_per_day}
              </div>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={inputPercent}
              />
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#4AD4F0]">
              <div>Output Tokens Used:</div>
              <div>
                {dashboardData?.output_tokens_today}/
                {dashboardData?.max_output_tokens_per_day}
              </div>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={outputPercent}
              />
            </div>
          </div>
        </div>
        <div className=" col-span-4 p-10 h-full flex flex-col justify-center">
          <img src={IMG} alt="dashboard img" />
        </div>
        <div className=" flex flex-col gap-3 justify-between col-span-3 p-4">
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#FF8144]">
              <div>
                You have an average quiz score of{" "}
                {dashboardData?.average_quiz_score} / 100
              </div>
              <div className="w-full flex justify-center">
                <div
                  onClick={() => (window.location.href = "/quizzes")}
                  className="bg-white text-[#4D30B5] p-2 rounded-md text-[15px]  text-center w-fit mt-5 cursor-pointer mb-2 hover:bg-gray-200 duration-300"
                >
                  View Quizzes
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#FF8144]">
              you are registered to {dashboardData?.school_name} school.
            </div>
          </div>

          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#F4B4D3]">
              <div>TTS Characters Used:</div>
              <div>
                {dashboardData?.total_tts_characters_today}/
                {dashboardData?.tts_max_characters_per_day}
              </div>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={ttsPercent}
              />
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#F4B4D3]">
              <div>STT Minutes Used:</div>
              <div>
                {dashboardData?.total_sst_minutes_today?.toFixed(2)}/
                {dashboardData?.sst_max_minutes_per_day}
              </div>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={sttPercent}
              />
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default AdminDashboard;
