import { Link, useLocation, useNavigate } from "react-router-dom";
import Boxmind from "../../Assets/boxMind_wide_white.png";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { logout } from "../../authSlice";
import { TbLogout } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { BiBook, BiChat } from "react-icons/bi";
import { FiSidebar, FiUsers } from "react-icons/fi";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { BiSolidSchool } from "react-icons/bi";
import { FaRankingStar } from "react-icons/fa6";
import { MdOutlineSubscriptions, MdSupportAgent } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { FaTableList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { PiExam } from "react-icons/pi";
import { FaEye } from "react-icons/fa";

function SideMenu({
  mainSidebarOpen,
  changeMainSidebarOpen,
  changeSideActive,
  userData,
  setUserData,
  setUserID,
}: any) {
  const location = useLocation();
  const { pathname } = location;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [lookUpDropdownOpen, setLookUpDropdownOpen] = useState(false);
  const [schoolManagementDropdownOpen, setSchoolManagementDropdownOpen] =
    useState(false);

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_id");
    localStorage.removeItem("courseId");
    localStorage.removeItem("chapterId");
    setUserID(null);
    setUserData(null);
    dispatch(logout());
    navigate("/signin");
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setWindowWidth(currentWidth);

      if (currentWidth <= 1024 && !mainSidebarOpen) {
        changeMainSidebarOpen();
      }
    };

    // Run the handler on mount to check initial window size
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mainSidebarOpen, changeMainSidebarOpen]);

  return (
    <menu className="text-[white] font-semibold text-[16px] h-screen select-none">
      <div
        className="text-[#a9aaac] font-semibold overflow-y-scroll custom-scrollbar h-screen  "
        style={{ maxHeight: "calc(100vh - 150px)" }}
      >
        <div className="overflow-y-scroll custom-scrollbar ">
          <div className="py-4 flex flex-col gap-2">
            {userData?.roles?.includes("student") && (
              <div className=" flex flex-col gap-2 ">
                <div
                  className={`${
                    pathname === "/dashboard"
                      ? " border-l-2 border-[white]"
                      : null
                  }`}
                  onClick={() => changeSideActive()}
                  role="button"
                >
                  <Link
                    className={`${
                      !mainSidebarOpen && " justify-center"
                    } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/dashboard"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                    to={"/dashboard"}
                  >
                    <RxDashboard title="Users" size={25} />
                    {mainSidebarOpen && "Dashboard"}
                  </Link>
                </div>
              </div>
            )}

            {userData?.roles?.includes("superadmin") && (
              <div className=" flex flex-col gap-2 ">
                <div
                  className={`${
                    pathname === "/dashboard"
                      ? " border-l-2 border-[white]"
                      : null
                  }`}
                  onClick={() => changeSideActive()}
                  role="button"
                >
                  <Link
                    className={`${
                      !mainSidebarOpen && " justify-center"
                    } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/dashboard"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                    to={"/dashboard"}
                  >
                    <RxDashboard title="Users" size={25} />
                    {mainSidebarOpen && "Dashboard"}
                  </Link>
                </div>
              </div>
            )}

            <div className=" flex flex-col gap-2">
              <div
                className={`${
                  pathname
                    .slice(1)
                    .replaceAll("-", " ")
                    .substring(
                      0,
                      location.pathname.slice(1).indexOf("/") > 0
                        ? location.pathname.slice(1).indexOf("/")
                        : location.pathname.length
                    ) === "chat"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`${
                    !mainSidebarOpen && " justify-center"
                  } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname
                        .slice(1)
                        .replaceAll("-", " ")
                        .substring(
                          0,
                          location.pathname.slice(1).indexOf("/") > 0
                            ? location.pathname.slice(1).indexOf("/")
                            : location.pathname.length
                        ) === "chat"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/chat"}
                  onClick={mainSidebarOpen && changeMainSidebarOpen}
                >
                  <BiChat title="chat" size={25} />
                  {mainSidebarOpen && "Chat"}
                </Link>
              </div>
            </div>

            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname
                    .slice(1)
                    .replaceAll("-", " ")
                    .substring(
                      0,
                      location.pathname.slice(1).indexOf("/") > 0
                        ? location.pathname.slice(1).indexOf("/")
                        : location.pathname.length
                    ) === "courses"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`${
                    !mainSidebarOpen && " justify-center"
                  } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname
                        .slice(1)
                        .replaceAll("-", " ")
                        .substring(
                          0,
                          location.pathname.slice(1).indexOf("/") > 0
                            ? location.pathname.slice(1).indexOf("/")
                            : location.pathname.length
                        ) === "courses"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                  to={"/courses"}
                >
                  <BiBook title="Courses" size={25} />
                  {mainSidebarOpen && "Courses"}
                </Link>
              </div>
            </div>

            {userData?.roles?.includes("teacher") && (
              <div className=" flex flex-col gap-2 ">
                <div
                  className={`${
                    pathname
                      .slice(1)
                      .replaceAll("-", " ")
                      .substring(
                        0,
                        location.pathname.slice(1).indexOf("/") > 0
                          ? location.pathname.slice(1).indexOf("/")
                          : location.pathname.length
                      ) === "teacher grades"
                      ? " border-l-2 border-[white]"
                      : null
                  }`}
                  onClick={() => changeSideActive()}
                  role="button"
                >
                  <Link
                    className={`${
                      !mainSidebarOpen && " justify-center"
                    } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                    ${
                      pathname
                        .slice(1)
                        .replaceAll("-", " ")
                        .substring(
                          0,
                          location.pathname.slice(1).indexOf("/") > 0
                            ? location.pathname.slice(1).indexOf("/")
                            : location.pathname.length
                        ) === "teacher grades"
                        ? "text-[white] hover:text-[#20144C]"
                        : null
                    }`}
                    to={"/teacher-grades"}
                  >
                    <FaRankingStar title="Grades" size={25} />
                    {mainSidebarOpen && "Grades"}
                  </Link>
                </div>
              </div>
            )}
            {userData?.roles?.includes("student") && (
              <div className=" flex flex-col gap-2 ">
                <div
                  className={`${
                    pathname === "/quizzes"
                      ? " border-l-2 border-[white]"
                      : null
                  }`}
                  onClick={() => changeSideActive()}
                  role="button"
                >
                  <Link
                    className={`${
                      !mainSidebarOpen && " justify-center"
                    } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/quizzes"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                    to={"/quizzes"}
                  >
                    <PiExam title="Users" size={25} />
                    {mainSidebarOpen && "Quizzes"}
                  </Link>
                </div>
              </div>
            )}

            {(userData?.roles?.includes("schooladmin") ||
              userData?.roles?.includes("superadmin")) && (
              <>
                <div
                  onClick={() =>
                    setSchoolManagementDropdownOpen(
                      !schoolManagementDropdownOpen
                    )
                  }
                  className={`${
                    !mainSidebarOpen ? " justify-center" : "justify-between"
                  }  flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  {!mainSidebarOpen && <BiSolidSchool title="info" size={25} />}
                  {mainSidebarOpen && (
                    <>
                      <div className="flex flex-row items-center gap-3">
                        <BiSolidSchool title="info" size={25} />
                        <p>School Management</p>
                      </div>
                      <div>
                        <RiArrowDropDownLine
                          className={` duration-300 ${
                            schoolManagementDropdownOpen === true
                              ? " rotate-180"
                              : " rotate-0 "
                          }`}
                          size={25}
                        />
                      </div>
                    </>
                  )}
                </div>
                {schoolManagementDropdownOpen && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: -2 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -2 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className={`${
                        mainSidebarOpen && "ml-5"
                      } flex flex-col gap-2 `}
                    >
                      {userData?.roles?.includes("superadmin") && (
                        <>
                          <div className=" flex flex-col gap-2 ">
                            <div
                              className={`${
                                pathname
                                  .slice(1)
                                  .replaceAll("-", " ")
                                  .substring(
                                    0,
                                    location.pathname.slice(1).indexOf("/") > 0
                                      ? location.pathname.slice(1).indexOf("/")
                                      : location.pathname.length
                                  ) === "schools"
                                  ? " border-l-2 border-[white]"
                                  : null
                              }`}
                              onClick={() => changeSideActive()}
                              role="button"
                            >
                              <Link
                                className={`${
                                  !mainSidebarOpen && " justify-center"
                                } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                              ${
                                pathname
                                  .slice(1)
                                  .replaceAll("-", " ")
                                  .substring(
                                    0,
                                    location.pathname.slice(1).indexOf("/") > 0
                                      ? location.pathname.slice(1).indexOf("/")
                                      : location.pathname.length
                                  ) === "schools"
                                  ? "text-[white] hover:text-[#20144C]"
                                  : null
                              }`}
                                to={"/schools"}
                              >
                                <BiSolidSchool title="Schools" size={25} />
                                {mainSidebarOpen && "Schools"}
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "school_grades"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "school_grades"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                            to={"/school_grades"}
                          >
                            <FaRankingStar title="Users" size={25} />
                            {mainSidebarOpen &&
                              (userData?.roles?.includes("superadmin")
                                ? "School Classes"
                                : "Classes")}
                          </Link>
                        </div>
                      </div>

                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "school_subscriptions"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "school_subscriptions"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                            to={"/school_subscriptions"}
                          >
                            <MdOutlineSubscriptions title="Users" size={25} />
                            {mainSidebarOpen &&
                              (userData?.roles?.includes("superadmin")
                                ? "School Subscriptions"
                                : "Subscriptions")}
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            )}

            {userData?.roles?.includes("schooladmin") && (
              <div className=" flex flex-col gap-2 ">
                <div
                  className={`${
                    pathname === "/users" ? " border-l-2 border-[white]" : null
                  }`}
                  onClick={() => changeSideActive()}
                  role="button"
                >
                  <Link
                    className={`${
                      !mainSidebarOpen && " justify-center"
                    } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/users"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                    to={"/users"}
                  >
                    <FiUsers title="Users" size={25} />
                    {mainSidebarOpen && "Users"}
                  </Link>
                </div>
              </div>
            )}

            {userData?.roles?.includes("superadmin") && (
              <>
                <div
                  onClick={() => setLookUpDropdownOpen(!lookUpDropdownOpen)}
                  className={` ${
                    !mainSidebarOpen ? " justify-center" : "justify-between"
                  } flex flex-row items-center cursor-pointer p-3 gap-3 rounded-lg hover:text-[#20144C] hover:bg-[white] duration-300`}
                >
                  {!mainSidebarOpen && <FaTableList title="info" size={25} />}
                  {mainSidebarOpen && (
                    <>
                      <div className="flex flex-row items-center gap-3">
                        <FaTableList title="info" size={25} />
                        <p>Look Up Tables</p>
                      </div>
                      <div>
                        <RiArrowDropDownLine
                          className={` duration-300 ${
                            lookUpDropdownOpen === true
                              ? " rotate-180"
                              : " rotate-0 "
                          }`}
                          size={25}
                        />
                      </div>
                    </>
                  )}
                </div>
                {lookUpDropdownOpen && (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: -2 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -2 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className={`${
                        mainSidebarOpen && "ml-5"
                      } flex flex-col gap-2 `}
                    >
                      {/* <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "data agents"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname
                                .slice(1)
                                .replaceAll("-", " ")
                                .substring(
                                  0,
                                  location.pathname.slice(1).indexOf("/") > 0
                                    ? location.pathname.slice(1).indexOf("/")
                                    : location.pathname.length
                                ) === "data agents"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/data-agents"}
                          >
                            <MdSupportAgent title="Agents" size={25} />
                            {mainSidebarOpen && "Agents"}
                          </Link>
                        </div>
                      </div> */}
                      {/* <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "tags"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname
                                .slice(1)
                                .replaceAll("-", " ")
                                .substring(
                                  0,
                                  location.pathname.slice(1).indexOf("/") > 0
                                    ? location.pathname.slice(1).indexOf("/")
                                    : location.pathname.length
                                ) === "tags"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/tags"}
                          >
                            <SlCloudUpload title="Tags" size={25} />
                            {mainSidebarOpen && "Tags"}
                          </Link>
                        </div>
                      </div> */}
                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "grades"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname
                                .slice(1)
                                .replaceAll("-", " ")
                                .substring(
                                  0,
                                  location.pathname.slice(1).indexOf("/") > 0
                                    ? location.pathname.slice(1).indexOf("/")
                                    : location.pathname.length
                                ) === "grades"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/grades"}
                          >
                            <FaRankingStar title="Classes" size={25} />
                            {mainSidebarOpen && "Classes"}
                          </Link>
                        </div>
                      </div>
                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "plans"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname
                                .slice(1)
                                .replaceAll("-", " ")
                                .substring(
                                  0,
                                  location.pathname.slice(1).indexOf("/") > 0
                                    ? location.pathname.slice(1).indexOf("/")
                                    : location.pathname.length
                                ) === "plans"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/plans"}
                          >
                            <MdOutlinePayment title="Plans" size={25} />
                            {mainSidebarOpen && "Plans"}
                          </Link>
                        </div>
                      </div>
                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname === "/users"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                          ${
                            pathname === "/users"
                              ? "text-[white] hover:text-[#20144C]"
                              : null
                          }`}
                            to={"/users"}
                          >
                            <FiUsers title="Users" size={25} />
                            {mainSidebarOpen && "Users"}
                          </Link>
                        </div>
                      </div>
                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "roles"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname
                                .slice(1)
                                .replaceAll("-", " ")
                                .substring(
                                  0,
                                  location.pathname.slice(1).indexOf("/") > 0
                                    ? location.pathname.slice(1).indexOf("/")
                                    : location.pathname.length
                                ) === "roles"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/roles"}
                          >
                            <IoExtensionPuzzleOutline title="Roles" size={25} />
                            {mainSidebarOpen && "Roles"}
                          </Link>
                        </div>
                      </div>
                      <div className=" flex flex-col gap-2 ">
                        <div
                          className={`${
                            pathname
                              .slice(1)
                              .replaceAll("-", " ")
                              .substring(
                                0,
                                location.pathname.slice(1).indexOf("/") > 0
                                  ? location.pathname.slice(1).indexOf("/")
                                  : location.pathname.length
                              ) === "chapters published status"
                              ? " border-l-2 border-[white]"
                              : null
                          }`}
                          onClick={() => changeSideActive()}
                          role="button"
                        >
                          <Link
                            className={`${
                              !mainSidebarOpen && " justify-center"
                            } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                            ${
                              pathname
                                .slice(1)
                                .replaceAll("-", " ")
                                .substring(
                                  0,
                                  location.pathname.slice(1).indexOf("/") > 0
                                    ? location.pathname.slice(1).indexOf("/")
                                    : location.pathname.length
                                ) === "chapters published status"
                                ? "text-[white] hover:text-[#20144C]"
                                : null
                            }`}
                            to={"/chapters_published_status"}
                          >
                            <FaEye title="Roles" size={25} />
                            {mainSidebarOpen && "Chapters Published Status"}
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                )}
              </>
            )}

            <div className=" flex flex-col gap-2 ">
              <div
                className={`${
                  pathname
                    .slice(1)
                    .replaceAll("-", " ")
                    .substring(
                      0,
                      location.pathname.slice(1).indexOf("/") > 0
                        ? location.pathname.slice(1).indexOf("/")
                        : location.pathname.length
                    ) === "profile"
                    ? " border-l-2 border-[white]"
                    : null
                }`}
                onClick={() => changeSideActive()}
                role="button"
              >
                <Link
                  className={`${
                    !mainSidebarOpen && " justify-center"
                  } ml-1 duration-200 hover:text-[#20144C] hover:bg-[white] rounded-lg p-2 hover:cursor-pointer flex flex-row items-center cursor-pointer gap-4 
                  ${
                    pathname
                      .slice(1)
                      .replaceAll("-", " ")
                      .substring(
                        0,
                        location.pathname.slice(1).indexOf("/") > 0
                          ? location.pathname.slice(1).indexOf("/")
                          : location.pathname.length
                      ) === "profile"
                      ? "text-[white] hover:text-[#20144C]"
                      : null
                  }`}
                  to={"/profile"}
                >
                  <CgProfile title="Profile" size={25} />
                  {mainSidebarOpen && "Profile"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex flex-col gap-2 mt-2 ">
        <div className="flex flex-row justify-between h-10 ml-1">
          <button
            onClick={handleLogout}
            className=" text-[#9B9CA1]  flex flex-row gap-2 items-center font-medium hover:text-[white] duration-300"
          >
            <TbLogout size={22} />
            {mainSidebarOpen && <p>Log Out</p>}
          </button>
          {window.innerWidth > 1024 && (
            <div className={`flex flex-row justify-center`}>
              <button
                type="button"
                onClick={changeMainSidebarOpen}
                className={`mr-2 hover:bg-[#45328b] flex flex-row text-white duration-300 rounded-md items-center bg-[#20144C] `}
              >
                <FiSidebar size={20} />
              </button>
            </div>
          )}
        </div>
        <div className=" fixed left-1 bottom-0">
          <p>v0.0.1</p>
        </div>
      </div>
    </menu>
  );
}

export default SideMenu;
